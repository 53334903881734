@charset 'UTF-8';

@import "abstracts/variables", "abstracts/mixins";

@import "base/base", "base/temp", "base/helpers";

// Auth
.auth-layout {
	min-height: 100vh;
	background-color: #e8e8e8;
}

.auth-logo-text {
	text-align: center;
}

.auth-error-text {
	color: red;
}

.auth-button {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.auth-link {
	float: right;
	margin-top: 5px;
	margin-left: 5px;
}

.auth-link-separator {
	color: #e5e5e5;
}

// Header
.trigger {
	padding: 0 24px;
	font-size: 18px;
	line-height: 64px;
	cursor: pointer;
	transition: color 0.3s;
}

.trigger:hover {
	color: #1890ff;
}

.header {
	background: #fff;
	padding: 0;
	margin: 0;
	//line-height: 0;
}

.header-title {
	margin-bottom: 0.5rem;
}

.header-profile-link svg {
	margin-right: 10px;
}

.header-zone-btn {
	margin: 0 16px;
	float: right;
}

.lang {
	margin-top: 16px;
	margin-right: 16px;
}

// Layout
.main-layout {
	min-height: 100vh;
}

// Content
.content {
	background: #fff;
	margin: 16px;
	padding: 24px;
}

// DrawerFooter
.drawer-footer {
	text-align: right;
}

.drawer-footer-button {
	margin-right: 8px;
}

// Table
.statistic-grid {
	background-color: #18eeff0d;
}

.table-row-cursor {
	cursor: pointer;
}

// Search
.table-search-clear-btn {
	color: rgba(0, 0, 0, 0.25);
	cursor: pointer;
	font-size: 13px;
}

.table-search-clear-btn:hover {
	color: rgba(0, 0, 0, 0.5);
	cursor: pointer;
	font-size: 13px;
}

// Card
.card-shadow {
	box-shadow: 5px 8px 24px 5px rgba(0, 0, 0, 0.06);
}

// RoleSwitcher
.role-switcher {
	width: 160px;
}

// Lot Numbers
.lot-numbers {
	display: flex;
	align-items: center;
}

.lot-numbers-number {
	width: 55%;
}
.lot-numbers-plus {
	width: 10%;
}
.lot-numbers-count {
	width: 35%;
}

// SSO Google
.fa-google-wrapper {
	display: inline-block;
	width: 1em;
	height: 1em;
	line-height: 1em;
	margin-right: 0.2em;
	background: center/100% 100% no-repeat url("../images/google-color.svg");
}

// Submenu
.sub-menu svg {
	margin-right: 10px;
}

// action button
.action-button svg {
	margin-right: 0.2rem;
}

// User settings
.user-settings-input {
	width: 190px;
}

.profile {
	margin-top: 16px;
	margin-bottom: 16px;
}

.profile-row {
	margin-bottom: 5px;
}

// Empty
.ant-empty-normal svg {
	font-size: 4rem;
}

// Table Row
.ant-table .ant-table-tbody > tr:hover > td {
	background-color: unset;
}

.ant-table .ant-table-tbody .ant-table-row:hover {
  box-shadow: 0 1px 2px rgba(89, 87, 87, 0.4);
}

// Actions
.actions {
	display: none;
}

.ant-table .ant-table-tbody .ant-table-row:hover .actions {
	display: flex;
	position: absolute;
	right: 0;
	height: 60px;
	margin-top: 5px;
	margin-bottom: 5px;
	border-bottom: none;
	background:rgb(255, 255, 255);
}

.ant-table .ant-table-tbody .ant-table-row {
	height: 70px;
}

// Menu Design
.menu-modified:hover,
.sub-menu:hover {
  background-color: #F1F3F4;
}

.ant-menu svg {
	color: grey;
}

.ant-menu .ant-menu-item-selected:after {
	border-right: none;
}

.ant-menu-item:hover a,
.ant-menu-submenu:hover span,
.ant-menu-submenu-selected {
	color: #000;
}

.ant-menu-submenu:hover i {
	color: #000 !important;
}

.ant-menu-item-selected {
	background-color: rgba(52, 168, 82, 0.3) !important;
}

.ant-menu-submenu-title:active {
	background-color: #F1F3F4;
}

.ant-menu .ant-menu-item-selected a {
	color: rgb(52, 168, 82);
}

.ant-menu-item-selected svg {
	color: rgb(52, 168, 82) !important;
}

.ant-layout-sider-children {
	height: 100vh;
	position: relative;
}

.ant-menu-inline-collapsed .ant-menu-submenu {
  width: 79px !important;
}

// Logo
.logo {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 12rem;
}

.logo img {
	width: 100%;
	object-fit: cover;
}

